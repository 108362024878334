/*!
 * Bootstrap 4 multi dropdown navbar ( https://bootstrapthemes.co/demo/resource/bootstrap-4-multi-dropdown-navbar/ )
 * Copyright 2017.
 * Licensed under the GPL license
 */

// Countdown
$(document)
  .ready(function () {
    // Quick Links Sidebar
    console.log('script admin start');

    setTimeout(() => {
      // $('.quick-links-btn')
      //   .click(function () {
      //     $('.quick-links-box')
      //       .toggleClass("quick-links-box-show");
      //     $('.screen-overlay')
      //       .toggleClass("show");
      //     $('body')
      //       .css('overflow', 'hidden');
      //   });
      // $('.quick-links-box-close')
      //   .click(function () {
      //     $('.quick-links-box')
      //       .toggleClass("quick-links-box-show");
      //     $('.screen-overlay')
      //       .toggleClass("show");
      //     $('body')
      //       .css('overflow', 'auto');
      //   });

      // $('.quick-settings-btn')
      //   .click(function () {
      //     $('.quick-settings-box')
      //       .toggleClass("quick-settings-box-show");
      //     $('.screen-overlay')
      //       .toggleClass("show");
      //     $('body')
      //       .css('overflow', 'hidden');
      //   });
      // $('.quick-settings-box-close')
      //   .click(function () {
      //     $('.quick-settings-box')
      //       .toggleClass("quick-settings-box-show");
      //     $('.screen-overlay')
      //       .toggleClass("show");
      //     $('body')
      //       .css('overflow', 'auto');
      //   });

      $('.dropdown-menu a.dropdown-toggle')
        .on('click', function (e) {
          var $el = $(this);
          var $parent = $(this)
            .offsetParent(".dropdown-menu");
          if (!$(this)
            .next()
            .hasClass('show')) {
            $(this)
              .parents('.dropdown-menu')
              .first()
              .find('.show')
              .removeClass("show");
          }
          var $subMenu = $(this)
            .next(".dropdown-menu");
          $subMenu.toggleClass('show');

          $(this)
            .parent("li")
            .toggleClass('show');

          $(this)
            .parents('li.nav-item.dropdown.show')
            .on('hidden.bs.dropdown', function (e) {
              $('.dropdown-menu .show')
                .removeClass("show");
            });

          if (!$parent.parent()
            .hasClass('navbar-nav')) {
            $el.next()
              .css({ "top": $el[0].offsetTop, "left": $parent.outerWidth() - 4 });
          }
          return false;
        });

      // Tasks Important Active
      $('.task-actions')
        .on('click', '.important', function () {
          $(this)
            .toggleClass('active');
        });

      // Tasks Important Active
      $('.task-actions')
        .on('click', '.star', function () {
          $(this)
            .toggleClass('active');
        });

      // Loading
      $(function () {
        $("#loading-wrapper")
          .fadeOut(3000);
      });

      // Todo list
      $('.todo-body')
        .on('click', 'li.todo-list', function () {
          $(this)
            .toggleClass('done');
        });

      // Tooltip
      $(function () {
        $('[data-toggle="tooltip"]')
          .tooltip()
      })

      $(function () {
        $('[data-toggle="popover"]')
          .popover()
      })

      var checkList = $('.task-checkbox')
        , toDoCheck = checkList.children('input[type="checkbox"]');
      toDoCheck.each(function (index, element) {
        var $this = $(element)
          , taskItem = $this.closest('.task-block');
        $this.on('click', function (e) {
          taskItem.toggleClass('task-checked');
        });
      });
    }, 2000);
  });

// Bootstrap JS ***********
